import { Player } from "@lottiefiles/react-lottie-player";
import axios from "axios";
import { FormEvent, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import NavigationBar from "./NavigationBar";
import { BsChevronRight } from "react-icons/bs";
import LetterByLetter from "./LetterByLetter";

const HostLanding = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email.trim() !== "") {
      setLoading(true);
      const data = {
        Date: new Date().toLocaleDateString("en-GB"),
        Email: email.trim(),
      };
      axios
        .post(
          "https://sheet.best/api/sheets/0cc75350-be47-400d-a58f-711cd309b398",
          data
        )
        .then((res) => {
          setEmail("");
          setLoading(false);
        });
    }
  };

  return (
    <div
      className="showcase-host min-vh-100 text-light"
      style={{ filter: "drop-shadow(0px 20px 80px #7151A9)" }}
    >
      <Container>
        <NavigationBar />
        <div className="text-center py-3 px-3 pt-0">
          <Player
            src="Animation-3/data.json"
            className="player mb-3"
            loop
            autoplay
            style={{
              maxWidth: "360px",
              filter: "drop-shadow(0px 10px 200px #7151A9)",
            }}
          />
          <h2 className="fw-bolder text-primary-gradient">
            Transform Your Idle GPUs into a money making machine
          </h2>
          <LetterByLetter headline={"Unlock Easy Passive Income"} className="display-5 fw-bolder"></LetterByLetter>
          <p>
            Make your idle GPUs work for you and make easy money starting from
            AED 10 a day!*
          </p>
          <Form className="row row-cols-md-auto g-3 align-items-center justify-content-center mt-3 mb-5">
            <div className="col-12 col-md-5">
              <Form.Control
                type="email"
                placeholder="Enter Email"
                name="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className="col-12 col-md-auto">
              <Button
                variant="primary"
                type="submit"
                className={`w-100 ${loading ? "disabled" : ""}`}
              >
                Join the Waitlist
              </Button>

              {/* <Button variant="outline-light" type="submit" className={`w-100 ${loading ? "disabled" : ""}`}>
              <div className="d-flex align-items-center">
                Join Waitlist
                <BsChevronRight className="ms-2 align" />
              </div>
            </Button> */}
            </div>
            <small className="">
              Join the waitlist to get priority access and special discounts. We
              go live soon!
            </small>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default HostLanding;
