import Landing from "../components/Landing";
import DevelopersLanding from "../components/DevelopersLanding";
import Footer from "../components/Footer";
import Introduction from "../components/Introduction";
import MLDevBenefits from "../components/MLDevBenefits";
import Optimization from "../components/Optimization";
import OurMission from "../components/OurMission";

const ForDevelopersPage = () => {
  return (
    <>
      {/* <DevelopersLanding /> */}
      <Landing />
      <Introduction />
      <MLDevBenefits />
      {/* <Optimization /> */}
      <OurMission />
      <Footer />
    </>
  );
};

export default ForDevelopersPage;
