import Heading from "./Heading";

import { Player } from "@lottiefiles/react-lottie-player";
import { Container, Row, Col, Image, Button, Card } from "react-bootstrap";
import { BsCheck2Circle, BsChevronRight } from "react-icons/bs";
import BenefitCard from "./BenefitCard";
import { motion } from "framer-motion";

const CustomListItem = ({ text }: { text: string }) => {
  return (
    <li>
      <BsCheck2Circle size={24} className="me-2" />
      {text}
    </li>
  );
};

const Environemnts = () => {
  return (
    <div className="d-flex flex-wrap align-items-center justify-content-between gap-3 gap-md-2 mt-3">
      <Image
        src="Ubuntu_logo.png"
        alt="Ubuntu Logo"
        style={{ maxWidth: "80px", height: "auto" }}
      />
      <Image
        src="Tensorflow_logo.webp"
        alt="Tensorflow Logo"
        style={{ maxWidth: "80px", height: "auto" }}
      />
      <Image
        src="PyTorch_logo.png"
        alt="PyTorch Logo"
        style={{ maxWidth: "80px", height: "auto" }}
      />
      <Image
        src="Jupyter_logo.png"
        alt="Jupyter Logo"
        style={{ maxWidth: "80px", height: "auto" }}
      />
      <Image
        src="VSCode_logo.svg"
        alt="VSCode Logo"
        style={{ maxWidth: "80px", height: "auto" }}
      />
      <Image
        src="Nvidia_CUDA_logo.svg"
        alt="Nvidia CUDA Logo"
        style={{ maxWidth: "80px", height: "auto" }}
      />
    </div>
  );
};

interface MLDevBenefitItemProps {
  image: string;
  title: string;
  text: string;
  extra?: JSX.Element;
}

const MLDevBenefitItem = ({
  image,
  title,
  text,
  extra,
}: MLDevBenefitItemProps) => {
  return (
    <motion.div className="h-100" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 0.75}}>
    <Card
      className="border border-primary box-shadow h-100"
      style={{ backgroundColor: "#263238", minHeight: "250px" }}
    >
      <Card.Body className="p-4 d-flex align-items-center">
        <div className="">
          <div className="d-flex flex-column flex-md-row align-items-center gap-3">
            <Card.Img
              variant="top"
              src={image}
              alt={title}
              style={{ width: "150px", maxWidth: "100%", height: "auto" }}
              className="mb-3 mb-md-0"
            />
            <div>
              <Card.Title className="fw-bold">{title}</Card.Title>
              <Card.Text>{text}</Card.Text>
            </div>
          </div>
          {extra}
        </div>
      </Card.Body>
    </Card>
    </motion.div>
  );
};

const MLDevBenefits = () => {
  return (
    <>
      {/* <hr/> */}
      <div
        className="py-6 px-4 p-md-7 text-light bg-polka"
        style={{ filter: "drop-shadow(0px 10px 200px #7151A9)" }}
      >
        <Container>
          <Heading className="text-center">
            Why rent GPUs with us?
          </Heading>
          <Row xs={{ cols: 1 }} lg={{ cols: 2 }} className="g-4">
            <Col>
              <MLDevBenefitItem
                image="Affordable.svg"
                title="Affordable"
                text="Get your machine learning computation at a fraction of the costs of traditional cloud GPU providers."
              />
            </Col>
            <Col>
              <MLDevBenefitItem
                image="Optimized.svg"
                title="Flexible"
                text="Pay as you go and pay only for the specs and resources you choose to utilize. "
              />
            </Col>
            <Col>
              <MLDevBenefitItem
                image="QuickDev.svg"
                title="Quick"
                text="Connect to servers located physically closer to you, reduce network latency and accelerate your development."
              />
            </Col>
            <Col>
              <MLDevBenefitItem
                image="Code.svg"
                title="Ready To Code"
                text="Spend 75% less time configuring and focus on development with our one-click environment setups pre-installed with."
                extra={<Environemnts />}
              />
            </Col>
          </Row>
    <motion.div className="h-100" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 0.75}}>

          <Row className="mt-4">
            <Col>
              <div
                className="border border-primary box-shadow p-4"
                style={{
                  backgroundImage: "url('liquid.svg')",
                  backgroundSize: "cover",
                }}
              >
                <Row className="align-items-center g-5">
                  <Col md="8" className="order-2 order-md-1">
                    <h2 className="fw-bold h3 text-primary-gradient">
                      Optimize and Accelerate ML development with
                    </h2>
                    <h1
                      className="fw-bold mb-4"
                      style={{ textShadow: "0px 0px 10px #916DD5" }}
                    >
                      Distributed Training
                    </h1>
                    <p>
                      Train large models with demanding workloads by parallelly
                      processing them across multiple GPUs ensuring
                    </p>
                    <ul className="list-unstyled" style={{ color: "#C7ADFF" }}>
                      <CustomListItem text="Improved Scalabilty" />
                      <CustomListItem text="Reduced Training Time" />
                      <CustomListItem text="Fault Tolerance and Resilience" />
                      <CustomListItem text="Flexible Rentals" />
                    </ul>
                    <p>
                      Combine and scale GPUs as per your need and unleash the
                      power of a supercomputer from the comfort of your home!
                    </p>

                    <Button variant="outline-light" className="mt-2">
                      <div className="d-flex align-items-center">
                        Join Now
                        <BsChevronRight className="ms-2 align" />
                      </div>
                    </Button>
                  </Col>
                  <Col md="4" className="order-1 order-md-2">
                    <Image
                      src="Optimization.svg"
                      fluid
                      style={{
                        width: "400px",
                        filter: "drop-shadow(0px 10px 200px #7151A9)",
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          </motion.div>
        </Container>
      </div>
    </>
  );
};

export default MLDevBenefits;
