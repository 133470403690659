import "./App.scss";
import NavigationBar from "./components/NavigationBar";
import BecomeAHostPage from "./pages/BecomeAHostPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import 'bootstrap/dist/css/bootstrap.min.css';
import ForDevelopersPage from "./pages/ForDevelopersPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <NavigationBar> */}
          <Routes>
            <Route path="/" element={<ForDevelopersPage />} />
            <Route path="/host" element={<BecomeAHostPage />} />
          </Routes>
        {/* </NavigationBar> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
