import { Col, Container, Image, Row } from "react-bootstrap";
import Heading from "./Heading";
import { motion } from "framer-motion";

const OurMission = () => {
  return (
    <>
      <div className="py-6 px-4 pb-3 p-md-7 pb-md-6 text-light">
        <Container>
          <Row className="align-items-center g-5 text-center" xs={{ cols: 1 }}>
            <Col>
            <motion.div>
              <Image
                src="Innovation.svg"
                fluid
                style={{
                  width: "400px",
                  filter: "drop-shadow(0px 10px 200px #916DD5)",
                }}
              />
              {/* borderRadius: "813px", opacity: "0.75", background: "rgba(145, 109, 213, 0.55)", filter: "blur(100px)" */}
              </motion.div>
            </Col> 
            <Col>
              <Heading className="text-center">
                Together, let's democratize computation and shape the future of
                technology
                </Heading>
              <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1}}>
                We believe that everyone should have the opportunity to explore
                the limitless possibilities of AI, regardless of financial
                limitations.
              </motion.p>
              <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1}}>
                Our mission is to democratize GPU computation by transforming
                underutilized GPUs into a computation powerhouse empowering ML
                developers worldwide to pursue their ambitions and foster a
                thriving AI ecosystem.
              </motion.p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OurMission;
