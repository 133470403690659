import { Card, Col, Container, Row, Image } from "react-bootstrap";
import Heading from "./Heading";
import SubHeading from "./SubHeading";
import { motion } from "framer-motion";

interface BetterChoiceItemProps {
  image: string;
  title: string;
  text: string;
}

const BetterChoiceItem = ({ image, title, text }: BetterChoiceItemProps) => {
  return (
    <motion.div className="h-100" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 0.75}}>
    <Card
      className="border border-primary box-shadow h-100 text-start"
      style={{ backgroundColor: "#263238" }}
    >
      <Card.Body>
        <Card.Img
          variant="top"
          src={image}
          alt={title}
          style={{ height: "80px", maxHeight: "100%", width: "auto" }}
          className="mb-3"
        />
        <Card.Title className="fw-bold">{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
    </motion.div>
  );
};

const BetterChoices = () => {
  return (
    <>
      <div
        className="py-6 px-4 p-md-7 text-light text-center bg-polka"
        style={{ boxShadow: "-1px -39px 100px -4px #7151A9, 0px 10px 200px -20px #7151A9" }}
      >
        <Container>
          <SubHeading>
            By renting out your GPUs with us you are making
          </SubHeading>
          <Heading className="text-center">
            Better choices for you and the environment
          </Heading>
          <Row
            xs={{ cols: 1 }}
            sm={{ cols: 2 }}
            md={{ cols: 4 }}
            className="g-4"
          >
            <Col>
              <BetterChoiceItem
                image="Sustainable.svg"
                title="Promote Sustainable Computing"
                text="Renting out idle GPUs promotes efficient utilization of computing resources, reducing the environmental impact of new hardware production and e-waste.
"
              />
            </Col>
            <Col>
              <BetterChoiceItem
                image="Reduced.svg"
                title="Reduced Depreciation"
                text="Offset the depreciation costs associated with owning and maintaining your GPUs by renting them out to earn passive income for you.

"
              />
            </Col>
            <Col>
              <BetterChoiceItem
                image="Hourglass.svg"
                title="Extended GPU Lifespan"
                text="Proper maintenance and utilization during rental periods can potentially extend the lifespan of your GPUs.
"
              />
            </Col>
            <Col>
              <BetterChoiceItem
                image="Aid.svg"
                title="Aid AI and Innovation"
                text="Contribute to the advancement of AI by providing developers with access to powerful computing resources to pursue their research and innovations.
"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BetterChoices;
