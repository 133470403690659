import { Col, Container, Image, Row } from "react-bootstrap";
import Heading from "./Heading";
import SubHeading from "./SubHeading";
import { motion } from "framer-motion";

const EmbarkJourney = () => {
  return (
    <>
      <div
        className="py-6 px-4 text-light"
      >
        <Container>
          <Row className="align-items-center g-5 text-center" xs={{ cols: 1 }}>
            <Col>
            <motion.div className="" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1}}>
              <Image
                src="MaskEmbark.svg"
                alt="Embark"
                fluid
                style={{
                  width: "400px",
                  filter: "drop-shadow(0px 10px 200px #916DD5)"
                }}
              />
              </motion.div>
            </Col>
            <Col>
              <SubHeading>
                Join our thriving community of GPU providers and
              </SubHeading>
              <Heading className="text-center">
                Embark on your passive income journey today!
              </Heading>
              <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1}}>
                Don't let the potential your expensive GPUs go to waste. Join
                Decenode to transform your idle GPUs into a Powerhouse of
                Computation.
              </motion.p>
              <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1}}>
                Grab this lucrative opportunity now and support the growing
                community of ML developers. It's a win-win solution!
                </motion.p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EmbarkJourney;
