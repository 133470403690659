import { Col, Container, Row, Table } from "react-bootstrap";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import Heading from "./Heading";
import SubHeading from "./SubHeading";

interface TableBodyRowProps {
  text: string;
}

const TableBodyRow = ({ text }: TableBodyRowProps) => {
  return (
    <tr>
      <td>{text}</td>
      <td className="text-center">
        <BsCheckLg size={20} />
      </td>
      <td className="text-center text-secondary">
        <BsXLg size={20} />
      </td>
    </tr>
  );
};

const DecenodeAndCryptoMining = () => {
  return (
    <>
      <div
        className="py-6 px-4 text-light"
        style={{
          backgroundImage: "url('rose-petals.svg')",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <div className="text-center">
            <SubHeading>
              Make your expensive GPUs work for you
              </SubHeading>
           <Heading className="text-center">
              Why choose Decenode over Crypto-Mining
           </Heading>
          </div>

          <Table
            responsive
            className="text-light"
            style={{ maxWidth: "75%", margin: "auto" }}
            id="comparison-table"
          >
            <thead>
              <tr>
                <th>Parameters</th>
                <th className="text-center">Decenode</th>
                <th className="text-center">Crypto-Mining</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              <TableBodyRow text="No need of purchasing expensive hardware" />
              <TableBodyRow text="No complicated setup or technical expertise" />
              <TableBodyRow text="No volatility of crypto currencies" />
              <TableBodyRow text="No hidden transaction fee" />
              <TableBodyRow text="Promotes sustainability with less carbon footprint" />
            </tbody>
          </Table>
        </Container>
      </div>
    </>
  );
};

export default DecenodeAndCryptoMining;
