import { ReactNode } from "react";
import { Image, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const navigation = [
  { name: "For Developers", href: "/" },
  { name: "Host", href: "/host" },
  { name: "Blog", href: "/blog" },
  { name: "Contact", href: "/contact" },
];

interface NavigationBarProps {
  children?: ReactNode;
}

const NavigationBar = ({ children }: NavigationBarProps) => {
  return (
    <>
      <Navbar expand="lg" className="pt-3 pb-1">
        <Navbar.Brand href="#home">
          <Image
            src="Logo_Primary.svg"
            className="d-inline-block align-top"
            alt="Decenode Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="" defaultActiveKey="/">
            {navigation.map((item) => (
              <NavLink
                key={item.name}
                to={item.href}
                className={({ isActive }) => {
                  console.log(item.href + " " + isActive);
                  return "nav-link" + " " + (isActive ? "active" : "");
                }}
              >
                {item.name}
              </NavLink>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {children}
    </>
  );
};

export default NavigationBar;
