import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import {
  TbBrandInstagram,
  TbBrandLinkedin,
  TbBrandDiscord,
  TbBrandTwitter,
} from "react-icons/tb";
import { HiOutlineMail } from "react-icons/hi";
import { BsChevronRight } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="footer text-light position-relative overflow-hidden">
      <img
        src="wave-pattern.svg"
        style={{ backgroundColor: "#263238" }}
        alt="wave pattern"
      />
      <div className="position-absolute w-100 bottom-0">
        <Container>
          <div className="text-center mb-5">
            <h2 className="h5" style={{ textShadow: "0px 0px 10px #916DD5" }}>
              Get special discounts when we go live
            </h2>
            <h1
              className="fw-bold"
              style={{ textShadow: "0px 0px 10px #916DD5" }}
            >
              Join Waitlist Now!
            </h1>
            <Button href="waitlist-rentor" variant="outline-light" className="mt-1">
              <div className="d-flex align-items-center">
                Join Now
                <BsChevronRight className="ms-2 align" />
              </div>
            </Button>
          </div>
          <Row className="align-items-center mb-3">
            <Col md="auto" className="pb-2 pb-md-0 text-center">
              © 2023 Decenode FZ-LLC
            </Col>
            <Col md="auto" className="ms-auto">
              <Stack
                direction="horizontal"
                gap={3}
                className="text-light align-items-center justify-content-center"
              >
                <a className="text-light" href="mailto:info@decenode.com">
                  <HiOutlineMail size={30} />
                </a>
                <a className="text-light" href="https://twitter.com/decenode">
                  <TbBrandTwitter size={30} />
                </a>
                <a
                  className="text-light"
                  href="https://www.instagram.com/decenode/"
                >
                  <TbBrandInstagram size={30} />
                </a>
                <a
                  className="text-light"
                  href="https://www.linkedin.com/company/decenode/"
                >
                  <TbBrandLinkedin size={30} />
                </a>
                <a className="text-light" href="https://discord.gg/bkBbUpaBk6">
                  <TbBrandDiscord size={30} />
                </a>
              </Stack>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
