import { Card, Col, Container, Image, Row } from "react-bootstrap";
import Heading from "./Heading";
import { motion } from "framer-motion";

interface HostBenefitItemProps {
  image: string;
  title: string;
  text: string;
}

const HostBenefitItem = ({ image, title, text }: HostBenefitItemProps) => {
  return (
    <motion.div className="h-100" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 0.75}}>
    <Card
      className="border box-shadow border-primary h-100"
      style={{ backgroundColor: "#263238"}}
    >
      <Card.Body>
        <Card.Img
          variant="top"
          src={image}
          alt={title}
          style={{ width: "80px", maxWidth: "100%", height: "auto" }}
          className="mb-3"
        />
        <Card.Title className="fw-bold">{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
      </Card.Body>
    </Card>
    </motion.div>
  );
};

const HostBenefits = () => {
  return (
    <>
      <div className="py-6 px-4 p-md-7 text-light bg-polka" style={{boxShadow: "-1px -39px 100px -4px #7151A9, 0px 10px 200px -20px #7151A9" }}>
        <Container>
          {/* <h1 
            className="fw-bold mb-5 text-center"
            style={{ textShadow: "0px 0px 10px #916DD5" }}
          >
            Why rent out GPUs with us?
          </h1> */}

          <Heading className="text-center"> Why rent out GPUs with us? </Heading>
          <Row
            xs={{ cols: 1 }}
            sm={{ cols: 2 }}
            md={{ cols: 3 }}
            className="g-4"
          >
            <Col>
              <HostBenefitItem
                image="Zero.svg"
                title="Zero upfront investment"
                text="No need of purchasing new expensive hardware to start earning. Simply sign up, verify your GPUs, and set your rental rates."
              />
            </Col>
            <Col>
              <HostBenefitItem
                image="Quick.svg"
                title="Quick One-Step Setup"
                text="Simple user-friendly setup, no  technical expertise needed, so anyone with an eligible GPU can participate."
              />
            </Col>
            <Col>
              <HostBenefitItem
                image="Security.svg"
                title="Securely Encrypted"
                text="Fortified with robust encryption protocols to safeguard your information and protect your valuable resources from unauthorized access."
              />
            </Col>
            <Col>
              <HostBenefitItem
                image="Hassle.svg"
                title="Hassle-Free Operations"
                text="We handle the technical aspects, from installation, maintenance to matching with suitable renters, secure transactions and timely payments, so you can focus on earning."
              />
            </Col>
            <Col>
              <HostBenefitItem
                image="Flexible.svg"
                title="Flexible Rental Options"
                text="Choose from various rental plans that align with your schedule and usage preferences, ensuring maximum flexibility and control."
              />
            </Col>
            <Col>
              <HostBenefitItem
                image="Easy.svg"
                title="Easy Passive Income"
                text="Earn a steady stream of passive income from the comfort of your home, effectively monetizing your unused hardware."
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HostBenefits;
