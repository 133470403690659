import { Player } from "@lottiefiles/react-lottie-player";
import { Col, Container, Image, Row } from "react-bootstrap";
import Heading from "./Heading";
import SubHeading from "./SubHeading";
import { motion } from "framer-motion";

const EmployeeGPU = () => {
  return (
    <>
      <div
        className="py-6 px-4 p-md-7 text-light"
      >
        <Container>
          <Row className="align-items-center g-5">
            <Col md="4">
              <Player
                src="Animation-4/data.json"
                className="player"
                loop
                autoplay
                style={{
                  maxWidth: "400px",
                  height: "auto",
                }}
              />
            </Col>
            <Col md="8">
              <SubHeading>
                Our revolutionary GPU rental platform helps you
            </SubHeading>

              {/* <h1
                className="fw-bold mb-4"
                style={{ textShadow: "0px 0px 10px #916DD5" }}
              >
                Make money by employing your GPU to its full potential{" "}
              </h1> */}
              <Heading className="align-items-left">Make money by employing your GPU to its full potential</Heading>
              <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1}}>
                Decenode is designed to connect GPU providers like you with
                machine learning (ML) developers who need access to powerful
                computing resources.
              </motion.p>
              <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1.1}}>
                Our platform offers a seamless and secure way for you to rent
                out your idle GPUs to ML developers worldwide, earning a steady
                stream of revenue while contributing to the advancement of
                cutting-edge technologies.
              </motion.p>
              <motion.p  initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1.2}}>
                Make your expensive GPUs earn for you when you're not using them by hosting them on Decenode. 
              </motion.p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EmployeeGPU;
