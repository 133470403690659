// LetterByLetter

// Read More:
// https://uxmovement.com/content/increasing-headline-clicks-with-eyebrow-text/

import {motion} from "framer-motion";

const sentence ={
    hidden:{ opacity:1},
    visible:{
      opacity:1, transition:{
        delay:0.25, staggerChildren:0.05
      }
    }
  }
  
  const letter ={
    hidden: {opacity:0,y:50},
    visible:{
      opacity:1, y:0
    }
  }

type LetterByLetterProps = {
    headline: string,
    className?: string
}



const LetterByLetter = ({ headline, className = "" }: LetterByLetterProps) => {
    return (

        <motion.div variants={sentence} initial="hidden" whileInView="visible"> 
           {
            headline.split("").map((char,index)=>{
              return(
                <motion.span className={`${className}`} key={char+"-"+index} variants={letter}>{char}</motion.span> 
              )
            })
           }
           </motion.div>
    );
}

export default LetterByLetter;