import { Player } from "@lottiefiles/react-lottie-player";
import { motion } from "framer-motion";
import { Row, Col, Container, Image } from "react-bootstrap";
import SubHeading from "./SubHeading";
import Heading from "./Heading";

const Introduction = () => {
  return (
    <div className="py-6 px-4 p-md-7 text-light">
      <Container>
        <Row className="align-items-center g-md-6">
          <Col md="4">
            {" "}
            <Player
              src="Animation-4/data.json"
              className="player"
              loop
              autoplay
              style={{
                maxWidth: "400px",
                height: "auto",
                filter: "drop-shadow(0px 10px 200px #7151A9)",
              }}
            />
          </Col>
          <Col md="8">
            {/* <h4 className="text-uppercase fw-bold h6 mb-4" style={{color: "#C7ADFF"}}>About Us</h4> */}
            {/* <h2 className="fw-bold h3 text-primary-gradient">
              Your gateway to affordable ML Development
            </h2> */}
            <SubHeading>
            Your gateway to affordable ML Development
            </SubHeading>
            {/* <h1
              className="fw-bold mb-4"
              style={{ textShadow: "0px 0px 10px #916DD5" }}
            >
              A Revolutionary GPU rental platform
            </h1> */}
            <Heading className="align-items-left">A Revolutionary GPU rental platform</Heading>
            <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1}}>
              63% of ML developers are looking for more affordable alternatives
              to cloud computing.
            </motion.p>
            <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1.1}}>
              At Decenode, we connect ML developers with idle consumer GPUs
              hosted on our global decentralized network.{" "}
            </motion.p>
            <motion.p initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ ease: "easeInOut", duration: 1.2}}>
              By harnessing the vast potential of underutilized idle consumer
              GPUs scattered across a global decentralized network, our
              innovative GPU rental platform disrupts expensive cloud
              computation and provides ML developers with seamless access to
              affordable, high-performance computing.
            </motion.p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Introduction;
