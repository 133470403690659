import BetterChoices from "../components/BetterChoices";
import DecenodeAndCryptoMining from "../components/DecenodeAndCryptoMining";
import EmbarkJourney from "../components/EmbarkJourney";
import EmployeeGPU from "../components/EmployeeGPU";
import Footer from "../components/Footer";
import HostBenefits from "../components/HostBenefits";
import HostLanding from "../components/HostLanding";

const BecomeAHostPage = () => {
  return (
    <>
      <HostLanding />
      <EmployeeGPU />
      <HostBenefits />
      <DecenodeAndCryptoMining />
      <BetterChoices />
      <EmbarkJourney />
      <Footer />
    </>
  );
};

export default BecomeAHostPage;
